<template>
  <app-modal
    title="Organization session transaction"
    :width="700"
    @submit="onSubmit"
  >
    <div class="text-subtle mb-8">
      <p class="mb-2">
        To attach a transaction value to an organization session you can:
      </p>
      <ul class="list-disc pl-4">
        <li>
          Link it to an existing Hubspot deal+line-item(s) couple. Transaction
          value will be based on the line items value.
        </li>
        <li>
          Use credits from existing organization credit packs. Transaction value
          will be based on the credit unit price of that pack.
        </li>
      </ul>
    </div>

    <div class="grid gap-6">
      <section>
        <header class="flex items-center justify-between">
          <h3 class="font-semibold">
            From credit wallet
            <v-chip class="!font-semibold" color="info" label size="x-small"
              >Balance : {{ wallet?.balance }}</v-chip
            >
          </h3>
          <v-btn
            color="primary"
            size="x-small"
            variant="tonal"
            @click="onAddCreditPackLinkClick"
            >Add product</v-btn
          >
        </header>

        <ul class="mt-4 grid">
          <li
            v-for="(item, index) in form.fromWallet"
            :key="index"
            class="grid grid-cols-[auto_150px_min-content] gap-4"
          >
            <product-autocomplete
              v-model="item.product_key"
              :name="`product_${index}`"
              required
              @update:product="item.credits = $event?.credit_value ?? 0"
            />

            <app-form-input-text
              v-model="item.credits"
              label="Credits"
              :name="`credits_${index}`"
              required
              :rules="{ maxWalletBalance: true }"
              type="number"
            />

            <v-btn
              class="mt-1"
              icon="ph:trash"
              size="x-small"
              variant="flat"
              @click="form.fromWallet.splice(index, 1)"
            />
          </li>
        </ul>
      </section>

      <section>
        <header class="flex items-center justify-between">
          <h3 class="font-semibold">From Hubspot deal</h3>
          <v-btn
            v-if="!form.fromHubspot"
            color="primary"
            size="x-small"
            variant="tonal"
            @click="onAddHubspotLinkClick"
            >Add line items</v-btn
          >
        </header>

        <div
          v-if="form.fromHubspot"
          class="mt-4 grid grid-cols-[auto_min-content] gap-4"
        >
          <hubspot-deal-line-item-widget
            v-model:deal-id="form.fromHubspot.deal_id"
            v-model:line-item-ids="form.fromHubspot.line_item_ids"
            required-deal-id
            required-line-item-ids
          />

          <v-btn
            class="mt-1"
            icon="ph:trash"
            size="x-small"
            variant="flat"
            @click="form.fromHubspot = undefined"
          />
        </div>
      </section>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { defineRule } from "vee-validate";

import ProductAutocomplete from "~/business-areas/product/components/product-autocomplete.vue";
import type { OrganizationSessionTransactionCreationRequestSchema } from "~~/model/organization-session.model";

const properties = defineProps<{
  organizationId: DatabaseTable<"organizations">["id"];
  transactions: DatabaseTable<"transactions">[];
}>();

const [{ data: wallet }, { data: transactions }] = await Promise.all([
  useAsyncData(async () => {
    const { data } = await useDatabaseClient()
      .from("organization_view_wallets")
      .select()
      .eq("organization_id", properties.organizationId)
      .single();

    return data;
  }),
  useAsyncData(async () => {
    const { data } = await useDatabaseClient()
      .from("transactions")
      .select("*, hubspot: hubspot_associations(*)")
      .in(
        "id",
        properties.transactions.map((t) => t.id),
      );

    return data;
  }),
]);

const form = reactive<OrganizationSessionTransactionCreationRequestSchema>({
  fromHubspot: transactions.value
    ?.filter((t) => t.credit_pack_id === null)
    .reduce(
      (hubspotAssociation, transaction) => {
        if (!transaction.hubspot) return hubspotAssociation;

        if (hubspotAssociation) {
          hubspotAssociation.line_item_ids?.push(
            ...(transaction.hubspot.line_item_ids ?? []),
          );
        } else {
          hubspotAssociation = {
            deal_id: transaction.hubspot.deal_id,
            line_item_ids: transaction.hubspot.line_item_ids,
          };
        }

        return hubspotAssociation;
      },
      undefined as OrganizationSessionTransactionCreationRequestSchema["fromHubspot"],
    ),
  fromWallet:
    transactions.value
      ?.filter((t) => t.credit_pack_id !== null)
      .map((t) => {
        return {
          credits: t.credits ?? 0,
          product_key: t.product,
        };
      }) ?? [],
});

function onAddCreditPackLinkClick() {
  form.fromWallet.push({
    product_key: undefined as unknown as string,
    credits: undefined as unknown as number,
  });
}

function onAddHubspotLinkClick() {
  form.fromHubspot = {
    deal_id: undefined as unknown as string,
    line_item_ids: [],
  };
}

defineRule("maxWalletBalance", () => {
  if (
    form.fromWallet.reduce((total, item) => total + item.credits, 0) >
    (wallet.value?.balance ?? 0)
  ) {
    return "Not enough credits!";
  }

  return true;
});

function onSubmit() {
  useModal("organizationSessionTransactionEditionModal").close({
    confirmed: true,
    payload: form,
  });
}
</script>
