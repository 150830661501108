<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="query"
    data-cy-product
    item-title="item.label"
    item-value="item.key"
    :items="results"
    label="Product"
    :name="name"
    :required="required"
    :return-object="false"
  />
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";
const model = defineModel<DatabaseTable<"products">["key"]>();
const product = defineModel<DatabaseTable<"products"> | undefined>("product");

defineProps<{
  required?: boolean;
  name?: string;
}>();

const { data } = useAsyncData(
  async () => {
    const { data } = await useDatabaseClient().from("products").select();

    return data ?? [];
  },
  {
    default: () => [],
  },
);

watch(model, (key) => {
  product.value = data.value.find((p) => p.key === key);
});

const query = ref("");
const { results } = useFuse(query, data, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: { keys: ["label", "key"], threshold: 0.2 },
});
</script>
